import * as React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import BeFeaturedBanner from "../components/beFeaturedBanner"

import award from "../images/Autos2050-Award.png"
import impactIcon from "../images/icons/Autos2050_impact_icon.png"
import ingenuityIcon from "../images/icons/Autos2050_ingenuity_icon.png"
import leadershipIcon from "../images/icons/Autos2050_leadership_icon.png"

import { motion } from "framer-motion"

function AwardsPage({ data }) {
  const site = (data || {}).site
  // console.log(site)
  // const [sponsors, setSponsors] = useState([])

  // useEffect(() => {
  //   setSponsors(data.sponsors)
  // }, [data.sponsors])
  // console.log(awards)
  return (
    <Layout title="awards">
      <Seo title="Awards" />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <section className="flex flex-col items-center justify-center py-32 w-full text-center bg-awards-page-header-colorized bg-cover bg-center bg-no-repeat">
          <h1 className="mb-4 text-white text-4xl italic font-bold lg:text-6xl xl:text-7xl">
            AWARDS
          </h1>
          <p className="mx-auto w-10/12 max-w-lg text-white font-bold lg:text-lg">
            {site && site.edges[0].node.heroText}
          </p>
        </section>
        <section className="flex flex-col items-center justify-center py-16 w-full bg-white-left-starburst bg-cover bg-center bg-no-repeat">
          <div className="container flex flex-wrap items-center justify-center mx-auto">
            <div className="grid place-items-center my-4">
              <img src={award} alt="Autos2050 Award" />
            </div>
            <p className="my-4 w-10/12 max-w-xl text-gray-500">
              {site && site.edges[0].node.featuredDescription}
            </p>
          </div>
        </section>
        <section className="py-16 w-full text-center bg-dark-200 lg:py-24">
          <h2 className="mb-8 text-secondary-200 text-2xl font-bold lg:text-4xl">
            Awards Program
          </h2>
          <div className="flex flex-wrap justify-center mx-auto space-y-8 md:space-x-8 md:space-y-0">
            <div className="w-10/12 max-w-xs text-left text-white">
              <p className="text-lg lg:text-xl">
                {site.edges[0].node.programDescription[0].children[0].text}
              </p>
              <br />
              <p>{site.edges[0].node.programDescription[1].children[0].text}</p>
              <div className="text-center lg:text-left">
                <Link to="/sponsorship">
                  <button className="mt-8 px-8 py-2 text-white text-sm font-bold bg-secondary-200 hover:bg-opacity-75 rounded-full focus:outline-none transition lg:text-base">
                    SPONSORSHIP OPPORTUNITIES
                  </button>
                </Link>
              </div>
            </div>
            <div className="pl-16 pr-8 py-8 w-10/12 max-w-xl text-left text-white bg-black lg:pl-24 lg:pr-16">
              <h3 className="mb-8 text-secondary-100 text-xl lg:text-2xl">
                Award Categories
              </h3>
              {site.edges[0].node.awards[0] && (
                <div className="relative mx-auto my-4">
                  <h4 className="mb-2 text-lg italic font-bold lg:text-xl">
                    {site.edges[0].node.awards[0].name}
                  </h4>
                  <p>{site.edges[0].node.awards[0].description}</p>
                  <img
                    className="absolute -left-12 top-0 w-8 lg:-left-16 lg:w-10"
                    src={
                      site.edges[0].node.awards[0].logo.asset.url ||
                      ingenuityIcon
                    }
                    alt="Ingenuity Icon"
                  />
                </div>
              )}
              {site.edges[0].node.awards[1] && (
                <div className="relative mx-auto my-4">
                  <h4 className="mb-2 text-lg italic font-bold lg:text-xl">
                    {site.edges[0].node.awards[1].name}
                  </h4>
                  <p>{site.edges[0].node.awards[1].description}</p>
                  <img
                    className="absolute -left-12 top-0 w-8 lg:-left-16 lg:w-10"
                    src={
                      site.edges[0].node.awards[1].logo.asset.url || impactIcon
                    }
                    alt="Impact Icon"
                  />
                </div>
              )}
              {site.edges[0].node.awards[2] && (
                <div className="relative mx-auto my-4">
                  <h4 className="mb-2 text-lg italic font-bold lg:text-xl">
                    {site.edges[0].node.awards[2].name}
                  </h4>
                  <p>{site.edges[0].node.awards[2].description}</p>
                  <img
                    className="absolute -left-12 top-0 w-8 lg:-left-16 lg:w-10"
                    src={
                      site.edges[0].node.awards[2].logo.asset.url ||
                      leadershipIcon
                    }
                    alt="Leadership Icon"
                  />
                </div>
              )}
            </div>
          </div>
        </section>
        <section className="flex flex-col items-center justify-center px-4 py-16 text-white bg-gray-right-starburst bg-cover bg-center bg-no-repeat md:flex-row md:space-x-8 lg:px-0 lg:py-24 lg:space-x-16">
          <h2 className="mb-4 w-10/12 max-w-sm text-primary text-2xl font-bold md:mb-0 lg:p-4 lg:text-4xl">
            Areas of consideration include
          </h2>
          <ul className="mx-2 my-2 w-64 font-bold list-inside list-disc space-y-2 md:my-0 lg:text-lg">
            {site &&
              site.edges[0].node.consideration
                .slice(0, 6)
                .map((consideration, i) => <li key={i}>{consideration}</li>)}
          </ul>
          <ul className="mx-2 w-64 font-bold list-inside list-disc space-y-2 lg:text-lg">
            {site &&
              site.edges[0].node.consideration
                .slice(6, 12)
                .map((consideration, i) => <li key={i}>{consideration}</li>)}
          </ul>
        </section>
        <section className="py-16 w-full text-center lg:py-24">
          <h2 className="mb-4 text-secondary-200 text-2xl font-bold lg:text-4xl">
            Award Criteria
          </h2>
          <p className="mx-auto my-4 w-10/12 max-w-3xl text-left text-gray-500">
            {site.edges[0].node.awardCriteriaDescription[0].children[0].text}
          </p>
          <p className="mx-auto my-4 w-10/12 max-w-3xl text-left text-gray-500 font-bold">
            {site.edges[0].node.awardCriteriaDescription[1].children[0].text}
          </p>
          <div className="flex flex-col justify-center mx-auto my-8 w-10/12 max-w-3xl text-left space-y-4 lg:flex-row lg:space-x-4 lg:space-y-0">
            <div className="w-3/4 max-w-xs lg:w-1/4">
              <h3 className="mb-4 text-primary text-xl italic font-bold lg:text-3xl">
                {site.edges[0].node.awardCriteria[0].title}
              </h3>
              <p className="text-gray-500 italic font-bold">
                {site.edges[0].node.awardCriteria[0].subtitle}
              </p>
            </div>
            <p className="w-10/12 max-w-lg text-gray-500">
              {site.edges[0].node.awardCriteria[0].description}
            </p>
          </div>
          <div className="flex flex-col justify-center mx-auto my-8 w-10/12 max-w-3xl text-left space-y-4 lg:flex-row lg:space-x-4 lg:space-y-0">
            <div className="w-3/4 max-w-xs lg:w-1/4">
              <h3 className="mb-4 text-primary text-xl italic font-bold lg:text-3xl">
                {site.edges[0].node.awardCriteria[1].title}
              </h3>
              <p className="text-gray-500 italic font-bold">
                {site.edges[0].node.awardCriteria[1].subtitle}
              </p>
            </div>
            <p className="w-10/12 max-w-lg text-gray-500">
              {site.edges[0].node.awardCriteria[1].description}
            </p>
          </div>
          <div className="flex flex-col justify-center mx-auto my-8 w-10/12 max-w-3xl text-left space-y-4 lg:flex-row lg:space-x-4 lg:space-y-0">
            <div className="w-3/4 max-w-xs lg:w-1/4">
              <h3 className="mb-4 text-primary text-xl italic font-bold lg:text-3xl">
                {site.edges[0].node.awardCriteria[2].title}
              </h3>
              <p className="text-gray-500 italic font-bold">
                {site.edges[0].node.awardCriteria[2].subtitle}
              </p>
            </div>
            <p className="w-10/12 max-w-lg text-gray-500">
              {site.edges[0].node.awardCriteria[2].description}
            </p>
          </div>
          <div className="flex flex-col justify-center mx-auto my-8 w-10/12 max-w-3xl text-left space-y-4 lg:flex-row lg:space-x-4 lg:space-y-0">
            <div className="w-3/4 max-w-xs lg:w-1/4">
              <h3 className="mb-4 text-primary text-xl italic font-bold lg:text-3xl">
                {site.edges[0].node.awardCriteria[3].title}
              </h3>
              <p className="text-gray-500 italic font-bold">
                {site.edges[0].node.awardCriteria[3].subtitle}
              </p>
            </div>
            <p className="w-10/12 max-w-lg text-gray-500">
              {site.edges[0].node.awardCriteria[3].description}
            </p>
          </div>
        </section>
        <section className="flex flex-col items-center justify-center py-16 w-full text-white bg-blue-right-starburst bg-cover bg-center bg-no-repeat lg:py-24 lg:h-auto">
          <div className="mx-auto w-10/12 max-w-3xl text-center space-y-4">
            <h2 className="text-2xl italic font-bold lg:text-4xl">
              {site.edges[0].node.nominationHeadline}
            </h2>
            <p className="">{site.edges[0].node.nominationDescription}</p>
            {site.edges[0].node.hideNominationButton !== true && (
              <div>
                <Link to="/nominations">
                  <button className="min-w-300 px-4 py-2 hover:text-secondary-200 text-white font-bold hover:bg-white border-2 border-white rounded-full focus:outline-none transition">
                    {site.edges[0].node.nominationButton}
                  </button>
                </Link>
              </div>
            )}
          </div>
        </section>
        <BeFeaturedBanner />
      </motion.div>
    </Layout>
  )
}

export default AwardsPage

export const query = graphql`
  {
    awards: allSanityAward {
      edges {
        node {
          name
          logo {
            asset {
              url
            }
          }
          description
        }
      }
    }
    site: allSanityAwards {
      edges {
        node {
          awardCriteria {
            title
            subtitle
            description
          }
          awardCriteriaDescription {
            children {
              text
            }
          }
          awards {
            name
            logo {
              asset {
                url
              }
            }
            description
          }
          programDescription {
            children {
              text
            }
          }
          heroText
          featuredDescription
          consideration
          nominationHeadline
          nominationDescription
          nominationButton
          hideNominationButton
        }
      }
    }
  }
`
